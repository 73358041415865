import Vue from "vue";
import { ChartPlugin, LineSeries, DateTimeCategory, DataLabel } from "@syncfusion/ej2-vue-charts";
import { getSkeleton } from "@/helpers/chartHelper";
Vue.use(ChartPlugin);
export default Vue.extend({
    props: {
        granularity: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            dataSource: {
                data: [],
                average: 0.0,
                total: 0.0,
                color: null,
            },
            /*
            primaryXAxis: {
              valueType: "DateTimeCategory",
              skeleton: "yMd"
            },
             */
            marker: {
                dataLabel: { visible: true }
            },
            lastSearchData: null
        };
    },
    provide: {
        chart: [LineSeries, DateTimeCategory, DataLabel]
    },
    computed: {
        primaryXAxis() {
            const skeleton = getSkeleton(this.granularity);
            return {
                valueType: "DateTimeCategory",
                skeleton: skeleton
            };
        },
        averageString() {
            if (this.dataSource.average == undefined)
                return "";
            return this.dataSource.average.toString();
        },
        averageColor() {
            if (this.dataSource.color == undefined || this.dataSource.color == null)
                return "";
            return `background-color: ${this.dataSource.color}; border-color: ${this.dataSource.color}`;
        },
        totalString() {
            if (this.dataSource.total == undefined)
                return "";
            return this.dataSource.total.toString();
        }
    },
    watch: {
        granularity() {
            if (this.lastSearchData != null) {
                const { startDate, endDate, employeeId, officeId } = this.lastSearchData;
                this.loadData(startDate, endDate, employeeId, officeId);
            }
        }
    },
    methods: {
        async loadData(startDate, endDate, employeeId, officeId) {
            this.lastSearchData = {
                startDate,
                endDate,
                employeeId,
                officeId
            };
            this.dataSource = {
                data: [],
                average: 0.0,
                total: 0.0,
                color: null,
            };
            this.$refs.chart.clearSeries();
            this.dataSource = await this.$store.dispatch("statistics/servicesTrend", {
                startDate,
                endDate,
                employeeId,
                officeId,
                granularity: this.granularity
            });
        }
    }
});
