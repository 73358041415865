import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import SearchToolbar from "@/components/Templates/Statistics/Performance/SearchToolbar.vue";
import WorkedHoursStatistics from "@/components/Charts/WorkedHoursStatistics.vue";
import ServicesTrendStatistics from "@/components/Charts/ServicesTrendStatistics.vue";
import ServiceStatistics from "@/components/Charts/ServiceStatistics.vue";
import ServiceEmployeeCostStatistics from "@/components/Charts/ServiceEmployeeCostStatistics.vue";
import ServiceCostOverRevenueStatistics from "@/components/Charts/ServiceCostOverRevenueStatistics.vue";
import ServiceProfitStatistics from "@/components/Charts/ServiceProfitStatistics.vue";
import ServiceRevenueStatistics from "@/components/Charts/ServiceRevenueStatistics.vue";
import AppointmentsTrialsStatistics from "@/components/Charts/AppointmentsTrialsStatistics.vue";
import AppointmentsTrialsOverNoNewAppointmentsStatistics from "@/components/Charts/AppointmentsTrialsOverNoNewAppointmentsStatistics.vue";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
Vue.use(ButtonPlugin);
Vue.use(TabPlugin);
export default Vue.extend({
    components: {
        "search-toolbar": SearchToolbar,
        "worked-hours-statistics": WorkedHoursStatistics,
        "services-trend-statistics": ServicesTrendStatistics,
        "service-statistics": ServiceStatistics,
        "service-employee-cost-statistics": ServiceEmployeeCostStatistics,
        "service-cost-revenue-statistics": ServiceCostOverRevenueStatistics,
        "service-profit-statistics": ServiceProfitStatistics,
        "service-revenue-statistics": ServiceRevenueStatistics,
        "appointments-trials-statistics": AppointmentsTrialsStatistics,
        "appointments-trials-over-no-new-appointments-statistics": AppointmentsTrialsOverNoNewAppointmentsStatistics
    },
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: "Performance dei professionisti",
            chartRefs: [
                "workedHoursStatistics",
                "serviceTrendStatistics",
                "serviceStatistics",
                "serviceEmployeeCostStatistics",
                "serviceCostOverRevenueStatistics",
                "serviceProfitStatistics",
                "serviceRevenueStatistics",
                "appointmentsTrialsStatistics",
                "appointmentsTrialsOverNoNewAppointmentsStatistics"
            ],
            updateChartsData: null,
            granularity: 3
        };
    },
    methods: {
        async updateCharts(params) {
            params = params || this.updateChartsData;
            this.updateChartsData = params;
            this.showSpinner();
            try {
                for (const p in this.chartRefs) {
                    await this.$refs[this.chartRefs[p]].loadData(params.startDate, params.endDate, params.employeeId, params.officeId);
                }
                this.hideSpinner();
            }
            catch (errors) {
                this.hideSpinner();
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                    error: errors[0].message
                }));
            }
        }
    }
});
